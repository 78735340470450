body {
  background-image: url('Taproom.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  height: 100%;
}



.App {
  text-align: center;
  color: #0A0608;
  font-family: 'Roboto';
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
  margin: 2rem;
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  margin-top: 2rem;
}

.App h1 {
    font-size: 1.5rem;
    font-weight: 900;
    color: white;
    text-shadow: 1px 1px 2px #0A0608; 
}

.container {
    text-align: left;
    background-color: #ffffff;
    opacity: 0.8;
    font-size: 1rem;
    line-height: 35px;
    margin-bottom: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.Title {
    font-size: 1.3rem;
}

.Beer svg{
    color: #BF9515;
}

.Video{
    margin-bottom: 50px;
}

.Video h3 {
    padding-bottom: 20px;
    font-weight: 900;
    color: white;
    text-shadow: 1px 1px 2px #0A0608;
}

iframe {
    border: 20px solid #0A0608;
    border-radius: 25px;
    box-shadow: 10px 5px 5px #0A0608;
}

@media screen and (max-width: 750px) {
    iframe {
        max-width: 100% !important;
        width: auto !important;
        height: auto !important;
    }
}

.Contact h3 {
    font-weight: 900;
}

.Contact a {
    color: #BF9515;
}

.Contact a:hover {
    color: #0A0608;
    text-decoration: none;
}

.btn {
    border: 1px solid #0A0608 !important;
    background-color: #0A0608 !important;
    font-weight: 700 !important;
}

.btn:hover {
    border: 1px solid #BF9515 !important;
    background-color: #BF9515 !important;
    color: #0A0608 !important;
}